exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-html-js": () => import("./../../../src/pages/aboutus.html.js" /* webpackChunkName: "component---src-pages-aboutus-html-js" */),
  "component---src-pages-accessibility-html-js": () => import("./../../../src/pages/accessibility.html.js" /* webpackChunkName: "component---src-pages-accessibility-html-js" */),
  "component---src-pages-contact-html-js": () => import("./../../../src/pages/contact.html.js" /* webpackChunkName: "component---src-pages-contact-html-js" */),
  "component---src-pages-cookies-html-js": () => import("./../../../src/pages/cookies.html.js" /* webpackChunkName: "component---src-pages-cookies-html-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-html-js": () => import("./../../../src/pages/privacy.html.js" /* webpackChunkName: "component---src-pages-privacy-html-js" */),
  "component---src-pages-services-html-js": () => import("./../../../src/pages/services.html.js" /* webpackChunkName: "component---src-pages-services-html-js" */),
  "component---src-pages-site-map-html-js": () => import("./../../../src/pages/siteMap.html.js" /* webpackChunkName: "component---src-pages-site-map-html-js" */),
  "component---src-pages-termuse-html-js": () => import("./../../../src/pages/termuse.html.js" /* webpackChunkName: "component---src-pages-termuse-html-js" */)
}

